import { useContext } from "react";
import { FeedContext } from "providers";

export const useFeed = () => {
  const context = useContext(FeedContext);
  if (!context) {
    throw new Error("useFeed must be used within a Feed Provider");
  }
  return context;
};
