import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { ScrollToTop, AdminRoute } from 'components';
import {PathRouteProps} from 'react-router';

const Home = loadable(() => import('./Home'));
const Disclaimer = loadable(() => import('./Disclaimer'));
const Gallery = loadable(() => import('./Gallery'));
const Reviews = loadable(() => import('./Reviews'));
const Episodes = loadable(() => import('./Episodes'));
const Episode = loadable(() => import('./Episode'));
const Ringtones = loadable(() => import('./Ringtones'));
const Contact = loadable(() => import('./Contact'));
const About = loadable(() => import('./About'));
const EightiesPhotos = loadable(() => import('./EightiesPhotos'));
const ArcadeQuiz = loadable(() => import('./ArcadeQuiz'));
const Store = loadable(() => import('./Store'));
const Product = loadable(() => import('./Product'));
const SignIn = loadable(() => import('./Auth/SignIn'));
const SignOut = loadable(() => import('./Auth/SignOut'));
const Admin = loadable(() => import('./Admin/Main'));
const AdminEpisodes = loadable(() => import('./Admin/Episodes'));
const AdminProducts = loadable(() => import('./Admin/Products'));
const AdminEpisode = loadable(() => import('./Admin/Episode'));

export interface PrivateRouteProps extends PathRouteProps {
  redirect?: string;
}

export const ROUTES = {
  home: '/',
  disclaimer: '/disclaimer',
  gallery: '/gallery',
  gallery_80s: '/gallery/80s',
  episodes: '/episodes',
  ringtones: '/ringtones',
  contact: '/contact',
  about: '/about',
  episode: (id = ':id') => `/episode/${id}`,
  arcade: (id = ':id') => `/quiz/arcade/${id}`,
  arcade_blank: '/quiz/arcade',
  cigars: '/cigars',
  store: '/store',
  shop: '/shop',
  shop_product: (id = ':id') => `/shop/product/${id}`,
  signin: '/auth/signin',
  auth: '/auth',
  signout: '/auth/signout',
  admin: '/admin',
  admin_episodes: '/admin/episodes',
  admin_edit_episode: (id = ':id') => `/admin/episode/${id}`,
  admin_products: '/admin/products',
};


export const InternalRoutes = () => {
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route index path={ROUTES.home} element={<Home/>} />
          <Route path={ROUTES.disclaimer} element={<Disclaimer/>} />
          <Route path={ROUTES.gallery} element={<Gallery/>} />
          <Route path={ROUTES.episodes} element={<Episodes/>} />
          <Route path={ROUTES.episode()} element={<Episode/>} />
          <Route path={ROUTES.ringtones} element={<Ringtones/>} />
          <Route path={ROUTES.contact} element={<Contact/>} />
          <Route path={ROUTES.about} element={<About/>} />
          <Route path={ROUTES.gallery_80s} element={<EightiesPhotos/>} />
          <Route path={ROUTES.arcade_blank} element={<ArcadeQuiz/>} />
          <Route path={ROUTES.arcade()} element={<ArcadeQuiz/>} />
          <Route path={ROUTES.cigars} element={<Store/>} />
          <Route path={ROUTES.shop} element={<Store/>} />
          <Route path={ROUTES.store} element={<Store/>} />
          <Route path={ROUTES.shop_product()} element={<Product/>} />
          <Route path={ROUTES.signin} element={<SignIn/>} />
          <Route path={ROUTES.signout} element={<SignOut/>} />
          <Route path={ROUTES.admin} element={<AdminRoute/>}>
            <Route path={ROUTES.admin} element={<Admin/>} />
            <Route path={ROUTES.admin_episodes} element={<AdminEpisodes/>} />
            <Route path={ROUTES.admin_products} element={<AdminProducts/>} /> 
            <Route path={ROUTES.admin_edit_episode()} element={<AdminEpisode/>} /> 
          </Route>
        </Routes>
        <Reviews/>
      </ScrollToTop>
    </Router>
  )
          
}