import {useReducer, useEffect} from 'react';
import {useFirebaseContext, useIntlText} from 'hooks';
import {reducer, validate} from 'lib';
import {login_schema} from 'schema';
import {RequiredField, IntlText, GenericObject} from 'components';
import {signInWithEmailAndPassword} from 'firebase/auth';
import LoginIcon from '@mui/icons-material/Login';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

const initialState = {
  password: '',
  email: '',
  errors: {},
  loading: false,
};

export const EmailLogin = ({
  onSuccess = (result: any) => {},
  defaultEmail = '',
}) => {
  const {auth} = useFirebaseContext();
  const {intlText} = useIntlText();
  const [{email, password, errors, loading}, dispatch] = useReducer(
    reducer,
    initialState
  );

  useEffect(() => {
    dispatch({ email: defaultEmail });
  },[defaultEmail]);

  const handleLoginResult = async () => {
    if (onSuccess && typeof onSuccess === 'function') onSuccess(null);
  };

  const login = async () => {
    dispatch({errors: {}});

    const formErrors = validate({email, password}, login_schema);

    if (formErrors) {
      let e: GenericObject = {};
      Object.keys(formErrors).forEach(key => {
        let name = '';
        switch (key) {
          case 'email':
            name = intlText('email');
            break;
          case 'password':
            name = intlText('password')
            break;
          default:
            break;
        }
        e[key] = (formErrors[key] as string)?.replace(key, name).replace(/"/g, '');
      });
      dispatch({errors: e});
      return false;
    }

    dispatch({loading: true});
    try {
      await signInWithEmailAndPassword(auth!, email, password);
      handleLoginResult();
    } catch (err: any) {
      console.log('Login Error:', err.message);
      dispatch({errors: {signin: true}});
    }
    dispatch({loading: false});
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    delete errors[name];
    dispatch({[name]: value, errors});
  };

  return (
    <Box mb={2}>
      {Object.keys(errors || {}).length > 0 && (
        <Alert severity='error'>
          <p>
            <IntlText id="login_error"/>
          </p>
          <p>
            <IntlText id="signin_error"/>
          </p>
        </Alert>
      )}
        {!defaultEmail && (
          <Box mb={1} mt={2}>
            <FormControl fullWidth>
              <RequiredField error={!!errors.email} noMessage={true}>
                <TextField
                  name="email"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon/>
                      </InputAdornment>
                    )
                  }}
                  value={email}
                  placeholder={intlText('email_address')}
                  onChange={handleChange}
                />
              </RequiredField>
            </FormControl>
          </Box>
        )}
        <Box mb={1}>
          <FormControl fullWidth>
            <RequiredField error={!!errors.password} noMessage={true}>
              <TextField
                name="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon/>
                    </InputAdornment>
                  )
                }}
                type="password"
                value={password}
                placeholder={intlText('password')}
                onChange={handleChange}
              />
            </RequiredField>
          </FormControl>
        </Box>
        <Button
          variant='contained'
          size="large"
          endIcon={<LoginIcon/>}
          fullWidth
          onClick={login}
          disabled={loading}
          color='primary'
        >
          <IntlText id="signin"/>
        </Button>
    </Box>
  );
};


