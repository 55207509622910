import { useState, useEffect } from 'react';
import { Deal } from "types";
import { deals as dealData } from 'data';
import { DateTime } from 'luxon';

export const useDeals: () => Deal[] = () => {
    const [deals, setDeals] = useState<Deal[]>([]);
    useEffect(() => {
        setDeals(dealData.filter(deal => !deal.expires || (deal.expires && DateTime.utc().toMillis() < DateTime.fromJSDate(new Date(`${deal.expires} 23:59:50`)).toMillis())));
    },[]);
    return deals;
}