

import { useFirebaseContext } from 'hooks';
import { CollectionReference, DocumentReference, collection, doc } from 'firebase/firestore';

export interface Refs {
  quizRef: (id: string) => DocumentReference;
  productRef: (id: string) => DocumentReference;
  productsRef: CollectionReference;
  episodeMetasRef: CollectionReference;
  episodeMetaRef: (id: string) => DocumentReference;
}

export const useRefs: () => any = () => {

    const { db } = useFirebaseContext();

    if (!db) return {};

    return {
        quizRef: (id: string) => doc(collection(db, 'quiz'), id),
        productRef: (id: string) => doc(collection(db, 'products'), id),
        productsRef: collection(db, 'products'),
        episodeMetasRef: collection(db, 'episode_meta'),
        episodeMetaRef: (id: string) => doc(collection(db, 'episode_meta'), id),
    }
  
};
