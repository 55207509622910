import React, {useMemo, useReducer, useEffect, PropsWithChildren} from 'react';
import {reducer} from 'lib';
import { EpisodeProduct } from 'types';
import { query, where, getDocs } from 'firebase/firestore';
import { useRefs } from 'hooks';
import { DateTime } from 'luxon';

export interface IStoreContext {
  products: EpisodeProduct[],
  dedupedProducts: EpisodeProduct[],
}

export const StoreContext =
  React.createContext<Partial<IStoreContext>>({});

export const StoreProvider = ({children}: PropsWithChildren) => {

    const { productsRef } = useRefs();

    const q = process.env.REACT_APP_STAGE !== 'production' 
        ?   query(
                productsRef
            )
        :   query(
                productsRef,
                where('availableAt', '<=', DateTime.utc().toJSDate())
            )

    const [{
        products = [],
    }, dispatch] = useReducer(reducer, {});

    useEffect(() => {
        (async () => {
            const docs = await getDocs(q);
            dispatch({ products: (docs?.docs || []).map((doc: any) => ({ ...doc.data(), id: doc.id, productLink: `https://nerdherfers.com/shop/product/${doc.id}`, availableAt: doc.get('availableAt')?.toDate() || DateTime.utc().toJSDate() })) });
        })();
    },[]);

    const dedupedProducts = useMemo(() => {  
        return products.filter((p1: EpisodeProduct, index: number) => products.findIndex((p2: EpisodeProduct) => p2.name === p1.name) === index)
    },[products]);

    return (
        <StoreContext.Provider value={{ products, dedupedProducts }}>
            {children}
        </StoreContext.Provider>
    );
};
