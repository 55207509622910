import { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { flash } from 'lib';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';

export const EmailSubscribeForm = ({ status, message, onValidated }: any) => {

    const [lastStatus, setLastStatus] = useState<string>(status);

    useEffect(() => {
        setLastStatus(status);
    },[status]);

    useEffect(() => {
        if (!lastStatus || !message) return;
        switch (lastStatus) { 
            case 'error':
                flash.error(message);
                break;
            case 'success':
                flash.success(message);
        }
    },[lastStatus, message]);

    const handleSubmit = () => {
        const email = document.getElementById('email') as HTMLInputElement;
        if (email?.value?.length > 5 && email?.value?.includes('@')) {
            onValidated({ MERGE0: email.value });
            email.value = '';
        }
    }

    return (
        <FormControl variant='filled' sx={{width:'100%'}}>
            <OutlinedInput 
                id="email"
                placeholder="Enter your e-mail"
                sx={{ backgroundColor: 'white' }}
                endAdornment={
                    <InputAdornment position="end">
                        <Button 
                            variant='contained' 
                            onClick={handleSubmit}
                            endIcon={<SendToMobileOutlinedIcon/>}
                        >
                            Subscribe
                        </Button>
                    </InputAdornment>
                    }
            />
        </FormControl>
    )
}