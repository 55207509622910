import {Navigate, useLocation, Outlet} from 'react-router-dom';
import {useFirebaseContext} from 'hooks';
import {ROUTES, PrivateRouteProps} from 'routes';

export const AdminRoute = ({
  redirect = ROUTES.signin,
  ...props
}: PrivateRouteProps) => {

  const {firebaseUser} = useFirebaseContext();

  const location = useLocation();

  if (!firebaseUser) {
    return <Navigate to={redirect} state={{from: location}} />;
  }

  return <Outlet />;
};
