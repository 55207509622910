import {PageTitle, IntlText, GenericObject} from 'components';
import { OAuthLogin } from './OAuthLogin';
import type {UserCredential, UserInfo} from 'firebase/auth';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface Props {
  onSuccess: (result: UserCredential | UserInfo) => void;
  state?: GenericObject | null; 
  providers?: string[];
}

export const LoginForm = ({onSuccess = () => {}, state = null, providers }: Props) => (
  <>
    <PageTitle textAlign='center'>
      <IntlText id="signin"/>
    </PageTitle>
    {state?.message && (
      <Box mb={2}>
        <Alert severity="info">{state.message}</Alert>
      </Box>
    )}
    <Divider/>
    <OAuthLogin onSuccess={onSuccess} providers={providers}/>
  </>
);
