import {useEffect, useReducer} from 'react';
import {useFirebaseContext} from 'hooks';
import {reducer} from 'lib';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import { EmailLogin } from './EmailLogin';
import {
  IntlText,
  GenericObject,
} from 'components';

import type {UserCredential} from 'firebase/auth';

const defaultProviders = ['password'];

interface Props {
  onSuccess?: (result: UserCredential) => void; 
  onError?: (result: any) => void;
  providers?: string[] | { id: string, action?: string }[];
  link?: boolean;
  signup?: boolean;
  permissions?: string[] | null | undefined,
}

const initialState = {
  loginProviders: defaultProviders,
  error: null,
}

const EXISTING_CREDENTIAL = 'auth/account-exists-with-different-credential';

export const OAuthLogin = ({
  providers = defaultProviders,
}: Props) => {

  const [{
    error,
    loginProviders,
  }, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ loginProviders: providers });
  }, [providers]);

  const {
  } = useFirebaseContext();

  return (
    <>
      {error && 
        <Alert severity="error">
          <IntlText id="signin_error"/>
          {error.code === EXISTING_CREDENTIAL 
            ? <>
                <p>
                  <IntlText id="email_linked"/>
                </p>
                <p>
                  <IntlText id="try_other_provider"/>
                </p>
              </>
            : <p>
                <IntlText id="email_maybe_linked"/>
              </p>
          }
        </Alert>
      }
      {['password'].map((provider: string | GenericObject) => {
        let providerId;
        let label;
        if (typeof provider === 'string') {
          providerId = provider;
        } else {
          const { id, action } = provider;
          providerId = id;
          label = action;
        }
        switch (providerId) {
          default: {
            return ( 
              <>
                <EmailLogin/>
                {loginProviders?.length > 1 && <Divider/>}
              </>
            ) 
          }
        }
      })}
    </>
  );
};


