import { ROUTES } from 'routes';

export const episodeMeta: Record<string, any> = [
    {
        season: 1,
        episode: 20, 
        episodeSlug: 'ep20-the-music-that-made-us',
        links: [
            { 
                title: 'Spotify Playlist',
                url: 'https://open.spotify.com/playlist/2EDhbVjekwQ9WTkxCVVOCb?si=c7b3f8a76ff44b51',
                description: 'A playlist of the songs discussed in this episode! You can listen below or click the link to listen on Spotify.'
            }
        ],
        embed: `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/playlist/2EDhbVjekwQ9WTkxCVVOCb?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`,
    },
    {
        season: 1,
        episode: 21, 
        episodeSlug: 'ep21-down-at-the-arcade',
        links: [{
            title: 'Arcade Quiz',
            route: ROUTES.arcade_blank,
            description: 'Take the arcade game quiz and test your knowledge!'
        }],
    },
]


