import { ExternalLink } from "./Link";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Platforms } from "./Platforms";

export const Listen = () => {
    return (
        <Box pb={5}>
            <Box mt={3} display='flex' flexDirection='row' justifyContent='center'>
                <Platforms/>
            </Box>
            <Box pl={5} pr={5} pt={2}>
                <Typography>
                    Listen to the <ExternalLink to="https://nerdherfers.podbean.com">Nerdherfers on Podbean</ExternalLink> or wherever you like to get your podcasts!
                </Typography>
            </Box>
        </Box>
    )
}