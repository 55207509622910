import { ReactNode } from 'react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';

export const CheckButton = ({ 
  onClick, 
  disabled = false, 
  loading = false,
  children,
  filled = false,
  variant,
  ...rest
}: LoadingButtonProps & { children?: ReactNode, filled?: boolean }) => (
  <LoadingButton 
    startIcon={<CheckIcon/>} 
    onClick={onClick} 
    disabled={disabled} 
    loading={loading}
    variant={variant ? variant : filled ? 'contained' : 'outlined'}
    { ...rest }
  >
    {children || <>Save</> }
  </LoadingButton>
);

export const CancelButton = ({ 
    onClick, 
    disabled = false, 
    loading = false,
    filled = false,
    children,
    close = false,
    variant,
    color = 'secondary',
  }: LoadingButtonProps & { children?: ReactNode, close?: boolean, filled?: boolean }) => ( 
      <LoadingButton 
        startIcon={<CancelIcon/>} 
        onClick={onClick} 
        disabled={disabled} 
        loading={loading}
        variant={variant ? variant : filled ? 'contained' : 'outlined'}
        color={color}
      >
        {children || <>{close ? "Close" : "Cancel"}</>}
      </LoadingButton>
    );