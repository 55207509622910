import {FormattedMessage, useIntl} from 'react-intl';
import {GenericObject} from './GenericObject';
import {AppName, FacebookText} from './Vars';

interface Props {
    id: string;
    values?: GenericObject;
    defaultMessage?: string;
}

export const IntlText = ({ id, values = {}, defaultMessage }: Props) => {
    const intl = useIntl();
    return (
        <FormattedMessage 
            id={id} 
            values={{
                app: <AppName/>,
                facebook: <FacebookText/>,
                ...values,
            }}
            defaultMessage={defaultMessage || intl.messages[id]}
        />
    )
}

