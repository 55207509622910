import styled from '@emotion/styled';

export interface BodyProps {
    top?: boolean;
    wide?: boolean;
    background?: string;
}

export const Body = styled.div<BodyProps>`
    width: 95%;
    margin: 0 auto;
    ${props => props.top ? 'padding-top: 20px;' : '0'}
    padding-bottom: 30px;
    ${props => props.background ? `background: url(${props.background}) no-repeat center;` : '#000000'}
    @media (min-width: 769px) {
        padding-top: 40px;
        max-width: ${props => props.wide ? '95%' : '1920px'};
    }
`