import type { Deal } from 'types';

export const deals: Deal[] = [
    {
        name: 'Daily Cigar Deal',
        text: '+ FREE shipping on your entire order!',
        link: 'https://www.dpbolvw.net/click-101255144-15748218',
    },
    {
        name: '10% Off $50+',
        text: 'Get 10% off orders of $50 or more!',
        expires: '03/01/2025',
        link: 'https://www.kqzyfj.com/click-101255144-15798964',
    },
    {
        name: '$20 Off',
        text: 'Get $20 off your order when you spend $99',
        expires: '03/01/2025',
        link: 'https://www.dpbolvw.net/click-101255144-15798962',
    },
    {
        name: '$30 Off',
        text: 'Get $30 off your order when you spend $200',
        expires: '03/01/2025',
        link: 'https://www.jdoqocy.com/click-101255144-15798958',
    },
    {
        name: '$45 Off',
        text: 'Get $45 off your order when you spend $300',
        expires: '03/01/2025',
        link: 'https://www.jdoqocy.com/click-101255144-15798959',
    },
    {
        name: '$60 Off',
        text: 'Get $60 off your order when you spend $400',
        expires: '03/01/2025',
        link: 'https://www.jdoqocy.com/click-101255144-15798960',
    },
    {
        name: 'Clearance',
        text: 'Shop the clearance section for great deals!',
        link: 'https://www.dpbolvw.net/click-101255144-15798948',
    }
];
