import { ReactElement, cloneElement } from 'react';
import { ExternalLink, InternalLink, NavMenu, Socials, CdnImage } from 'components';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ROUTES } from 'routes';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import QuizIcon from '@mui/icons-material/Quiz';

function ElevationScroll({ children }:{ children: ReactElement}) {

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
}

export const TopMenu = () => {
    return (
        <ElevationScroll>
            <AppBar 
                position="sticky" 
                sx={{ 
                    backgroundColor: '#4527be', //'#493935', 
                }}
            >
                <Toolbar>
                    <InternalLink to={ROUTES.home} color='#ffffff'>
                        <Stack direction="row" spacing={1}>
                            <CdnImage src="nerdherfers-logo_wName_inline_white.png" height={30} width={128} />
                        </Stack>
                    </InternalLink>
                    <Box 
                        mt={1}
                        sx={{ 
                            flexGrow: 1,
                            display: { xs: 'none', md: 'initial' },
                        }} 
                        pl={3}
                    >
                           <Socials color='#ffffff'/> 
                    </Box>
                    <Box 
                        flexGrow={1}
                        textAlign='right'
                        pr={3}
                    >
                        <Stack 
                            direction="row" 
                            spacing={1} 
                            justifyContent={'flex-end'}
                            divider={<Divider orientation="vertical" flexItem color='#ffffff'/>}
                            display={{ xs: 'none', md: 'flex' }}
                        >
                            <InternalLink to={ROUTES.arcade_blank}>
                                <Typography color='#ffffff' fontWeight='bolder'>
                                    <QuizIcon sx={{ verticalAlign: 'bottom' }}/> Arcade Quiz!
                                </Typography>
                            </InternalLink>
                            <InternalLink to={ROUTES.shop}>
                                <Typography color='#ffffff'>
                                    <StorefrontIcon sx={{ verticalAlign: 'bottom' }}/> Cigar Shop
                                </Typography>
                            </InternalLink>
                            <ExternalLink to="https://nerdherfers.etsy.com">
                                <ShoppingBagIcon sx={{ verticalAlign: 'bottom' }}/> Merch
                            </ExternalLink>
                        </Stack>
                    </Box>
                    <NavMenu/>
                </Toolbar>
            </AppBar>
        </ElevationScroll>
    )
}
