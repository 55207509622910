import { useContext } from "react";
import { StoreContext } from "providers";

export const useStoreContext = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error("useStoreContext must be used within a Store Provider");
  }
  return context;
};
