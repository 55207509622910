import { useMemo } from 'react';
import Box from '@mui/material/Box';
import { TikTokIcon } from './TikTokIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Stack from '@mui/material/Stack';
import { ExternalLink } from '.';
import Tooltip from '@mui/material/Tooltip';

export const Socials = ({ color = 'inherit' }) => {

    const socials = useMemo(() => [
        { 
            name: 'Instagram',
            icon: <InstagramIcon/>,
            url: 'https://instagram.com/nerdherfers',
        },
        { 
            name: 'Facebook',
            icon: <FacebookIcon/>,
            url: 'https://facebook.com/nerdherfers',
        },
        { 
            name: 'Youtube',
            icon: <YouTubeIcon/>,
            url: 'https://youtube.com/@nerdherfers',
        },
        { 
            name: 'TikTok',
            icon: <><TikTokIcon color={color}/></>,
            url: 'https://tiktok.com/@nerdherfers',
        },
    ], [color]);

    return (
        <Box>
            <Stack direction='row' spacing={2}>
                {socials.map(({ name, icon, url }, index) => (
                    <ExternalLink to={url} key={`_social_${index}`}>
                        <Tooltip title={`Follow us on ${name!}`}>
                            {icon}
                        </Tooltip>
                    </ExternalLink>
                ))}
            </Stack>
        </Box>
    )
}