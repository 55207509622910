
import MailChimpSubscribe from 'react-mailchimp-subscribe';
import { EmailSubscribeForm } from './EmailSubscribeForm';

export const EmailSubscribe = () => {

    return (
        <MailChimpSubscribe
            url={process.env.REACT_APP_MAILCHIMP_SUBSCRIBE || ''}
            render={({ subscribe, status, message }) => (
                <EmailSubscribeForm
                    status={status} 
                    message={message}
                    onValidated={(formData: any) => subscribe(formData)}
                />
            )}
        />
    )

}