import React, {useReducer, PropsWithChildren} from 'react';
import {reducer} from 'lib';

export interface ISessionContext {
  ready: boolean;
  locale: string;
}

export const SessionContext =
  React.createContext<Partial<ISessionContext>>({});

export const SessionProvider = ({children}: PropsWithChildren) => {

  const [{
    ready = true,
    locale = 'en',
  }, dispatch] = useReducer(reducer, {});

  return (
    <SessionContext.Provider value={{ ready, locale }}>
      {children}
    </SessionContext.Provider>
  );
};
