import Stack from '@mui/material/Stack';
import { CdnImage, ExternalLink } from '.';

const platforms = [{
    name: 'Amazon Music',
    link: 'https://music.amazon.com/podcasts/f6fbbcc8-9198-4f97-89c9-331a682f7856',
    icon: 'AmazonMusic.png',
},{
    name: 'Listen Notes',
    link: 'https://lnns.co/BIaofZ1lnph',
    icon: 'ListenNotes.png',
},{
    name: 'PlayerFM',
    link: 'https://player.fm/series/3555995',
    icon: 'PlayerFM.png',
},{
    name: 'Podchaser',
    link: 'https://www.podchaser.com/podcasts/nerdherfers-5642922',
    icon: 'Podchaser.webp',
},{
    name: 'Apple Podcasts',
    link: 'https://podcasts.apple.com/us/podcast/nerdherfers/id1734324193',
    icon: 'apple-podcast.png',
},{
    name: 'iHeartRadio',
    link: 'https://iheart.com/podcast/156293426',
    icon: 'iHeartRadio.png',
},{
    name: 'Podbean',
    link: 'https://nerdherfers.podbean.com',
    icon: 'podbean-app.png',
},{
    name: 'Spotify',
    link: 'https://open.spotify.com/show/5dkMShhvtY5Q2tGvtgjfJn',
    icon: 'spotify.png',
},{
    name: 'Youtube',
    link: 'https://www.youtube.com/playlist?list=PLbnzJu9t0ZUCjOQpIzPz_tJ1EwlYVEnj6',
    icon: 'youtube_logo.png',
}];

export const Platforms = (props: any) => {
    return (
        <Stack direction="row" spacing={{ xs: 1, md: 2 }} {...props}>
            {platforms.map(({ name, link, icon }, index) => (
                <ExternalLink to={link} key={`_pf_${index}`}>
                    <CdnImage alt={name} src={`icons/${icon}`} width={32} height={32}/>
                </ExternalLink>
            ))}
        </Stack>
    )
}